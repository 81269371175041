export const SEARCH = 'SEARCH';
export const SEARCH_STARS = 'SEARCH_STARS';

export const FETCH_FAILED = 'FETCH_FAILED';
export const SET_FAILED = 'SET_FAILED';

export const FETCH_SHORT = 'FETCH_SHORT';
export const FETCH_UTSG = 'FETCH_UTSG';
export const FETCH_UTSC = 'FETCH_UTSC';
export const FETCH_UTM = 'FETCH_UTM';

export const SET_SHORT = 'SET_SHORT';
export const SET_UTSG = 'SET_UTSG';
export const SET_UTSC = 'SET_UTSC';
export const SET_UTM = 'SET_UTM';

export const FETCH_TEST = 'FETCH_TEST';
export const SET_DUMMY_STATE = 'SET_DUMMY_STATE';
