import React from 'react';

import classes from './Input.module.scss';

const Input = (props) => {
  let inputEl = null;

  const inputClasses = [classes.InputEl];

  let warning = null;

  if (props.invalid && props.shoudValidate && props.touched) {
    inputClasses.push(classes.Invalid);
    warning = (
      <p
        style={{
          marginTop: '5px',
          marginBottom: '0',
          fontSize: '80%',
          fontWeight: 'bold',
          color: 'red',
        }}
      >
        {props.message}
      </p>
    );
  }

  if (props.elType === 'textarea') {
    inputClasses.push(classes.TextArea);
  }

  switch (props.elType) {
    case 'input':
      inputEl = (
        <React.Fragment>
          <label className={classes.Label}>{props.elConfig.inputname}</label>
          <input
            className={inputClasses.join(' ')}
            {...props.elConfig}
            value={props.value}
            onChange={props.changed}
          />
          {warning}
        </React.Fragment>
      );
      break;
    case 'textarea':
      inputEl = (
        <React.Fragment>
          <label className={classes.Label}>{props.elConfig.inputname}</label>
          <textarea
            className={inputClasses.join(' ')}
            {...props.elConfig}
            value={props.value}
            onChange={props.changed}
          />
          {warning}
        </React.Fragment>
      );
      break;
    case 'select':
      inputEl = (
        <React.Fragment>
          <label className={classes.Label}>{props.elConfig.placeholder}</label>
          <select
            className={inputClasses.join(' ')}
            value={props.value}
            onChange={props.changed}
          >
            {props.elConfig.options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.value}
              </option>
            ))}
          </select>
        </React.Fragment>
      );
      break;
    default:
      inputEl = (
        <input
          className={classes.InputEl}
          {...props.elConfig}
          value={props.value}
          onChange={props.changed}
        />
      );
  }

  return (
    <div className={classes.Input}>
      {/* <label className={classes.Label}>{props.label}</label> */}
      {inputEl}
    </div>
  );
};

export default Input;
