import React from 'react';
import { Link } from 'react-router-dom';

import classes from './Control.module.scss';

const Control = (props) => {
  return (
    <div className={classes.Control}>
      <div className={classes.Left}>
        <Link to='/campuses'>&larr;&nbsp;&nbsp;All UofTHomes</Link>
        <h1>{props.address}</h1>
      </div>
      <div className={classes.Review}>
        <Link to={{ pathname: '/rate', state: { address: props.address } }}>
          <button>Rate {props.address}</button>
        </Link>
      </div>
    </div>
  );
};

export default Control;
