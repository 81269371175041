import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { withRouter } from 'react-router-dom';

import * as actionTypes from '../../../store/actions/actionTypes';
import * as actions from '../../../store/actions/index';
// import Spinner from '../Spinner/Spinner';

import classes from './Search.module.scss';

const Search = (props) => {
  const [width, setWidth] = useState(window.innerWidth);

  const resizeHandler = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    return function cleanup() {
      window.removeEventListener('resize', resizeHandler);
    };
  });

  let text = 'Search';
  if (width > 450) {
    text = 'Search a UofTHome';
  }

  const [enteredHome, setEnteredHome] = useState('');
  const {
    UTSG,
    UTSC,
    UTM,
    onFetchUTSG,
    onFetchUTSC,
    onFetchUTM,
    loading,
  } = props;

  const setEnteredHomeHandler = (selected) => {
    setEnteredHome(selected.value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateOptions = () => {
    for (const key in UTSG.data) {
      const name = UTSG.data[key].name;
      optionList.push({ value: name, label: name });
    }
    for (const key in UTSC.data) {
      const name = UTSC.data[key].name;
      optionList.push({ value: name, label: name });
    }
    for (const key in UTM.data) {
      const name = UTM.data[key].name;
      optionList.push({ value: name, label: name });
    }
  };

  let optionList = [];
  if (!loading) {
    optionList = [];
    updateOptions();
  }

  // Fetch all UofTHomes from server
  useEffect(() => {
    if (UTSG.isFetched === false) {
      onFetchUTSG();
    } else if (UTSC.isFetched === false) {
      onFetchUTSC();
    } else if (UTM.isFetched === false) {
      onFetchUTM();
    }
  }, [UTM, UTSC, UTSG, onFetchUTM, onFetchUTSC, onFetchUTSG]);

  useEffect(() => {
    if (enteredHome !== '') {
      props.history.push(`/reviews/${enteredHome}`);
    }
    setEnteredHome('');
  }, [enteredHome, props.history]);

  // Custom style for the search bar
  const customStyles = {
    control: (base, state) => ({
      ...base,
      width: '100%',
      fontFamily: 'Mulish',
      fontSize: 18,
      border: state.isFocused ? 0 : 0,
      boxShadow: state.isFocused ? 0 : 0,
      cursor: 'text',
      borderRadius: '5px',
      borderBottom: 'solid 1px',
    }),

    option: (styles, { isFocused }) => {
      return {
        ...styles,
        width: '100%',
        cursor: 'pointer',
        backgroundColor: isFocused ? 'white' : 'white',
        color: isFocused ? '#032b5b' : 'black',
        lineHeight: 2,
      };
    },

    input: (styles) => ({
      ...styles,
      width: '100%',
      color: 'black',
      fontFamily: 'Mulish, Times New Roman, Times, Serif',
    }),

    menu: (styles) => ({
      ...styles,
      width: '100%',
      marginTop: 0,
      boxShadow: 'none',
      borderRadius: '5px',
      border: 'solid 1px #fff',
    }),

    singleValue: (styles) => ({
      ...styles,
    }),
  };

  return (
    <div>
      <Select
        className={classes.Search}
        value={enteredHome}
        options={optionList}
        onChange={setEnteredHomeHandler}
        placeholder={text}
        styles={customStyles}
        noOptionsMessage={() => 'Loading...'}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.homes.loading,
    UTSG: state.homes.UTSG,
    UTSC: state.homes.UTSC,
    UTM: state.homes.UTM,
    searchedHome: state.searchedHome.searchedHome,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSearch: () => dispatch({ type: actionTypes.SEARCH }),
    onFetchUTSG: () => dispatch(actions.fetchUTSG()),
    onFetchUTSC: () => dispatch(actions.fetchUTSC()),
    onFetchUTM: () => dispatch(actions.fetchUTM()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Search));
