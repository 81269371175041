import React from 'react';
import { Link } from 'react-router-dom';

import classes from './Footer.module.scss';

const Footer = () => {
  return (
    <footer className={classes.Footer}>
      <div className={classes.Container}>
        <ul>
          <li className={classes.title}>UofTHome</li>
          <li>
            <Link to='/'>Home</Link>
          </li>
          <li>
            <Link to='/campuses'>All UofTHomes</Link>
          </li>
          <li>
            <Link to='/rate'>Write A Review</Link>
          </li>

          <li>
            <a href='mailto:uofthome@gmail.com'>Contact Us</a>
          </li>
        </ul>
        <ul>
          <li className={classes.title}>Partner Site</li>
          <li>
            <a href='https://www.sihan.tech'>SI-HAN Tech</a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
