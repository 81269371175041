import React, { useRef, useEffect, useState } from 'react';

import classes from './Star.module.scss';

const Star = (props) => {
  let { rating } = props;
  let star = useRef(undefined);
  let [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(star.current.getBoundingClientRect().width);
  }, [star]);

  const stars = [];
  for (let i = 0; i < 5; i++) {
    stars.push(
      <div key={i}>
        <span>
          <div
            style={{
              width:
                rating - i >= 1 ? `${width}px` : `${(rating - i) * width}px`,
            }}
          >
            ★
          </div>
        </span>
        <span>&nbsp;&nbsp;</span>
      </div>
    );
  }

  return (
    <div className={classes.Stars}>
      <div className={classes.Fill}>{stars}</div>
      <div className={classes.Empty}>
        <span ref={star}>★</span>
        <span>&nbsp;&nbsp;</span>
        <span>★</span>
        <span>&nbsp;&nbsp;</span>
        <span>★</span>
        <span>&nbsp;&nbsp;</span>
        <span>★</span>
        <span>&nbsp;&nbsp;</span>
        <span>★</span>
      </div>
    </div>
  );
};

export default Star;
