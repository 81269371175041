import React from 'react';

import classes from './Button.module.scss';

const Button = (props) => {
  return (
    <div className={classes.Layout}>
      <button className={classes.Button} onClick={props.onChangeNew}>
        {props.children}
      </button>
    </div>
  );
};

export default Button;
