import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import Logo from '../../UI/Logo/Logo';
import Search from '../../UI/Search/Search';

import classes from './Toolbar.module.scss';

const Toolbar = (props) => {
  const [width, setWidth] = useState(window.innerWidth);

  const resizeHandler = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    return function cleanup() {
      window.removeEventListener('resize', resizeHandler);
    };
  });

  let rate = null;
  if (width > 705) {
    rate = (
      <nav>
        <ul>
          <li>
            <NavLink
              to='/rate'
              exact
              style={{ letterSpacing: '1px', fontWidth: 'bold' }}
            >
              Write A Review!
            </NavLink>
          </li>
        </ul>
      </nav>
    );
  } else {
    rate = null;
  }

  return (
    <header className={classes.Toolbar}>
      <Logo />
      <Search />
      {rate}
    </header>
  );
};

export default Toolbar;
