import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';

import Input from '../../UI/Input/Input';
import Button from './Button/Button';
import SpaceMo from '../../UI/SpaceMo/SpaceMo';

const Address = (props) => {
  const { loading, UTSG, UTSC, UTM } = props;
  const [address, setAddress] = useState('');

  // Handler for changing address
  let addressChangedHandler = null;
  if (!props.new) {
    addressChangedHandler = (addressInput) => {
      setAddress(addressInput.value);
      props.onChangeAddress(addressInput.value);
    };
  } else {
    addressChangedHandler = (event) => {
      setAddress(event.target.value);
      props.onChangeAddress(event.target.value);
    };
  }

  // Get width of screen
  const [width, setWidth] = useState(window.innerWidth);

  const resizeHandler = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    return function cleanup() {
      window.removeEventListener('resize', resizeHandler);
    };
  });

  // Set Button

  let addButtonText = "Add your UofTHome if you don't see it above!";
  if (width < 600) {
    addButtonText = 'Add your UofTHome';
  }

  let button = (
    <div style={{ width: '100%', textAlign: 'center' }}>
      <Button onChangeNew={props.onChangeNew}>{addButtonText}</Button>
      <div style={{ marginBottom: '30px' }}>
        <SpaceMo height={'150px'} />
      </div>
    </div>
  );
  if (props.new || !props.set) {
    button = null;
  }

  // Custom style for the search bar
  const customStyles = {
    control: (base, state) => ({
      ...base,
      width: '100%',
      fontFamily: 'Mulish',
      fontSize: 18,
      // border: state.isFocused ? 0 : 0,
      boxShadow: state.isFocused ? 0 : 0,
      cursor: 'text',
      borderRadius: '5px',
      border: 'solid 1px  #ccc',
    }),

    option: (styles, { isFocused }) => {
      return {
        ...styles,
        width: '100%',
        cursor: 'pointer',
        backgroundColor: isFocused ? 'white' : 'white',
        color: isFocused ? '#0966d6' : 'black',
        lineHeight: 2,
      };
    },

    input: (styles) => ({
      ...styles,
      width: '100%',
      color: 'black',
      fontFamily: 'Mulish, Times New Roman, Times, Serif',
    }),

    menu: (styles) => ({
      ...styles,
      width: '100%',
      marginTop: 0,
      boxShadow: 'none',
      border: 'solid 1px  #ccc',
      borderRadius: '5px',
    }),

    singleValue: (styles) => ({
      ...styles,
    }),
  };

  // Seach bar options
  let optionList = [];
  const updateOptions = () => {
    for (const key in UTSG.data) {
      const name = UTSG.data[key].name;
      optionList.push({ value: name, label: name });
    }
    for (const key in UTSC.data) {
      const name = UTSC.data[key].name;
      optionList.push({ value: name, label: name });
    }
    for (const key in UTM.data) {
      const name = UTM.data[key].name;
      optionList.push({ value: name, label: name });
    }
  };

  if (!loading) {
    optionList = [];
    updateOptions();
  }

  // Whether to display a search bar or a test input
  let addressInput = null;
  if (props.new) {
    addressInput = (
      <Input
        elType={'input'}
        elConfig={{
          type: 'text',
          placeholder: 'Address (e.g. 85 Wood St.)',
          inputname: 'Address',
        }}
        value={address}
        invalid={!address}
        shoudValidate={{
          required: true,
        }}
        touched={false}
        changed={(val) => addressChangedHandler(val)}
      />
    );
  } else {
    addressInput = (
      <Select
        value={{ value: props.address, label: props.address }}
        options={optionList}
        onChange={(val) => addressChangedHandler(val)}
        onInputChange={address}
        placeholder={address}
        styles={customStyles}
        noOptionsMessage={() => 'Loading...'}
      />
    );
  }

  // Conditionally add padding to addressInput
  let style = { padding: '6px 10px' };
  if (props.new) {
    style = null;
  }

  return (
    <div style={style}>
      {addressInput}
      {button}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.homes.loading,
    UTSG: state.homes.UTSG,
    UTSC: state.homes.UTSC,
    UTM: state.homes.UTM,
    searchedHome: state.searchedHome.searchedHome,
  };
};

export default connect(mapStateToProps, null)(React.memo(Address));
