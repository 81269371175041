import React, { useState } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';

import Input from '../../UI/Input/Input';
import Button from './Button/Button';

import classes from './Form.module.scss';

const Form = (props) => {
  const [formIsValid, setFormIsValid] = useState(false);

  let warning = (
    <p style={{ font: 'inherit', textAlign: 'center', fontWeight: 'bold' }}>
      Please fill out the form before submitting
    </p>
  );
  if (formIsValid) {
    warning = null;
  }

  const [rateForm, setRateForm] = useState({
    experienceRating: {
      elType: 'select',
      elConfig: {
        options: [
          { value: 5, displayValue: '5' },
          { value: 4, displayValue: '4' },
          { value: 3, displayValue: '3' },
          { value: 2, displayValue: '2' },
          { value: 1, displayValue: '1' },
        ],
        placeholder: 'Overall Experience',
      },
      value: 5,
      validation: {},
      valid: true,
    },
    homeRating: {
      elType: 'select',
      elConfig: {
        options: [
          { value: 5, displayValue: '5' },
          { value: 4, displayValue: '4' },
          { value: 3, displayValue: '3' },
          { value: 2, displayValue: '2' },
          { value: 1, displayValue: '1' },
        ],
        placeholder: 'Home Rating',
      },
      value: 5,
      validation: {},
      valid: true,
    },
    buildingRating: {
      elType: 'select',
      elConfig: {
        options: [
          { value: 5, displayValue: '5' },
          { value: 4, displayValue: '4' },
          { value: 3, displayValue: '3' },
          { value: 2, displayValue: '2' },
          { value: 1, displayValue: '1' },
        ],
        placeholder: 'Building Rating',
      },
      value: 5,
      validation: {},
      valid: true,
    },
    locationRating: {
      elType: 'select',
      elConfig: {
        options: [
          { value: 5, displayValue: '5' },
          { value: 4, displayValue: '4' },
          { value: 3, displayValue: '3' },
          { value: 2, displayValue: '2' },
          { value: 1, displayValue: '1' },
        ],
        placeholder: 'Location Rating',
      },
      value: 5,
      validation: {},
      valid: true,
    },
    roomType: {
      elType: 'select',
      elConfig: {
        options: [
          { value: 'Condo', displayValue: 'Condo' },
          { value: 'House', displayValue: 'House' },
          { value: 'Town House', displayValue: '2' },
        ],
        placeholder: 'Type of home',
      },
      value: 'Condo',
      validation: {},
      valid: true,
    },
    floorNumber: {
      elType: 'input',
      elConfig: {
        type: 'text',
        placeholder: 'Floor number',
        inputname: 'Floor number (Leave 0 if rather not say)',
      },
      value: 0,
      validation: {
        required: true,
        message: 'Please input the actual floor number',
        range: {
          max: 100,
          min: 0,
        },
      },
      valid: false,
      touched: false,
    },
    priceWhole: {
      elType: 'input',
      elConfig: {
        type: 'text',
        inputname: 'Price for the entire place (Leave 0 if not applicable)',
        placeholder: 'Rent',
      },
      value: 0,
      validation: {
        required: true,
        message: 'Please input the actual price',
        range: {
          max: 10000,
          min: 0,
        },
      },
      valid: false,
      touched: false,
    },
    priceRoom: {
      elType: 'input',
      elConfig: {
        type: 'text',
        inputname: 'Price for your room (Leave 0 if not applicable)',
        placeholder: 'Rent',
      },
      value: 0,
      validation: {
        required: true,
        message: 'Please input the actual price',
        range: {
          max: 10000,
          min: 0,
        },
      },
      valid: false,
      touched: false,
    },
    bedroomNumber: {
      elType: 'select',
      elConfig: {
        options: [
          { value: 1, displayValue: '1' },
          { value: 2, displayValue: '2' },
          { value: '2 + 1', displayValue: '2 + 1' },
          { value: 3, displayValue: '3' },
          { value: 0, displayValue: '0' },
        ],
        placeholder: 'Number of bedrooms',
      },
      value: 1,
      validation: {},
      valid: true,
    },
    bathroomNumber: {
      elType: 'select',
      elConfig: {
        options: [
          { value: 1, displayValue: '1' },
          { value: 2, displayValue: '2' },
          { value: 3, displayValue: '3' },
        ],
        placeholder: 'Number of bathrooms',
      },
      value: 1,
      validation: {},
      valid: true,
    },
    roomLive: {
      elType: 'select',
      elConfig: {
        options: [
          { value: 'Studio', displayValue: 'Studio' },
          { value: 'Single Bedroom', displayValue: 'Single Bedroom' },
          { value: 'Master Bedroom', displayValue: 'Master Bedroom' },
          { value: 'Den', displayValue: 'Den' },
          { value: 'Living Room', displayValue: 'Living Room' },
          { value: 'Share Room', displayValue: 'Share Room' },
        ],
        placeholder: 'Type of your room',
      },
      value: 'Single bedroom',
      validation: {},
      valid: true,
    },
    commentBody: {
      elType: 'textarea',
      elConfig: {
        type: 'text',
        placeholder:
          'Share your experience! Tell us what you like and dislike about your UofTHome.',
        inputname: 'Comment',
      },
      value: 'Test Comment',
      validation: {
        required: true,
        message: 'Please write a comment!',
        // length: {
        //   min: 10,
        // },
      },
      valid: false,
      touched: false,
    },
    region: {
      elType: 'select',
      elConfig: {
        options: [
          { value: 'UTSG', displayValue: 'UTSG' },
          { value: 'UTSC', displayValue: 'UTSC' },
          { value: 'UTM', displayValue: 'UTM' },
        ],
        placeholder: 'Which campus do you go to?',
      },
      value: 'UTSG',
      validation: {},
      valid: true,
    },
    yearLiveIn: {
      elType: 'select',
      elConfig: {
        options: [
          { value: 2020, displayValue: 2020 },
          { value: 2019, displayValue: 2019 },
          { value: 2018, displayValue: 2018 },
          { value: 2017, displayValue: 2017 },
          { value: 2016, displayValue: 2016 },
          { value: 2015, displayValue: 2015 },
          { value: 2014, displayValue: 2014 },
          { value: 2013, displayValue: 2013 },
          { value: 2012, displayValue: 2012 },
          { value: 2011, displayValue: 2011 },
          { value: 2010, displayValue: 2010 },
          { value: 2009, displayValue: 2009 },
          { value: 2008, displayValue: 2008 },
          { value: 2007, displayValue: 2007 },
          { value: 2006, displayValue: 2006 },
          { value: 2005, displayValue: 2005 },
          { value: 2004, displayValue: 2004 },
          { value: 2003, displayValue: 2003 },
          { value: 2002, displayValue: 2002 },
          { value: 2001, displayValue: 2001 },
          { value: 2000, displayValue: 2000 },
        ],
        placeholder: 'What Year did you live here?',
      },
      value: 2020,
      validation: {},
      valid: true,
    },
    yearInCollege: {
      elType: 'select',
      elConfig: {
        options: [
          { value: 'Freshman', displayValue: 'Freshman' },
          { value: 'Sophomore', displayValue: 'Sophomore' },
          { value: 'Junior', displayValue: 'Junior' },
          { value: 'Senior', displayValue: 'Senior' },
          { value: 'Master', displayValue: 'Master' },
          { value: 'PhD', displayValue: 'PhD' },
          { value: 'Rather not say', displayValue: 'Rather not say' },
          { value: 'N/A', displayValue: 'N/A' },
        ],
        placeholder: 'What Year In College did you live here?',
      },
      value: 'Freshman',
      validation: {},
      valid: true,
    },
  });

  // Handler for changing inputs
  const inputChangedHandler = (event, inputIdentifier) => {
    const updatedRateForm = {
      ...rateForm,
    };
    const updatedFormEl = { ...updatedRateForm[inputIdentifier] };
    updatedFormEl.value = event.target.value;
    updatedFormEl.valid = checkValidity(
      updatedFormEl.value,
      updatedFormEl.validation
    );
    updatedRateForm[inputIdentifier] = updatedFormEl;
    updatedFormEl.touched = true;
    let formIsValid = true;
    for (let inputIdentifier in updatedRateForm) {
      formIsValid = updatedRateForm[inputIdentifier].valid && formIsValid;
    }
    setRateForm({ ...updatedRateForm });
    setFormIsValid(formIsValid);
  };

  // Check form validity
  const checkValidity = (value, rules) => {
    let isValid = true;
    if (!rules) {
      return true;
    }
    if (rules.required) {
      isValid = value.trim() !== '' && isValid;
    }
    if (rules.length) {
      isValid = value.length > rules.length.min && isValid;
    }
    if (rules.range) {
      isValid = value <= rules.range.max && value >= rules.range.min && isValid;
    }
    return isValid;
  };

  // Handler for form submission
  const submitHandler = (event) => {
    event.preventDefault();
    const formData = {};
    for (let formElIdentifier in rateForm) {
      formData[formElIdentifier] = rateForm[formElIdentifier].value;
    }
    formData['address'] = props.address;
    axios
      .post('https://uofthome.herokuapp.com/comment', formData)
      .then((res) => {
        props.history.push({
          pathname: '/yay',
          state: { campus: rateForm['region'].value },
        });
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  let form = null;

  const formElArray = [];
  for (let key in rateForm) {
    formElArray.push({
      id: key,
      config: rateForm[key],
    });
  }
  form = (
    <form onSubmit={submitHandler}>
      {formElArray.map((formEl) => (
        <Input
          key={formEl.id}
          elType={formEl.config.elType}
          elConfig={formEl.config.elConfig}
          value={formEl.config.vaue}
          invalid={!formEl.config.valid}
          message={formEl.config.validation.message}
          shoudValidate={formEl.config.validation}
          touched={formEl.config.touched}
          changed={(event) => inputChangedHandler(event, formEl.id)}
        />
      ))}
      {warning}
      <Button
        btnType='Success'
        disabled={!formIsValid}
        className={classes.Button}
        style={{ margin: '10px auto' }}
      >
        Submit!
      </Button>
    </form>
  );

  return <div>{form}</div>;
};

export default React.memo(withRouter(Form));
