import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';

// import axios from '../../utilities/axios/axios-homeReviews';
import axios from 'axios';

import Control from './Control/Control';
import Header from './Header/Header';
import Review from './Review/Review';
import Spinner from '../UI/Spinner/Spinner';
import classes from './ReviewsPage.module.scss';

const ReviewsPage = (props) => {
  // Scroll to top when component rendered
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [reviews, setReviews] = useState();
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [experienceRating, setExperienceRating] = useState({});
  const [homeRating, setHomeRating] = useState({});
  const [buildingRating, setBuildingRating] = useState({});
  const [locationRating, setLocationRating] = useState({});
  const [region, setRegion] = useState('');

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        'https://uofthome.herokuapp.com/building?address=' +
          props.match.params.home
      )
      .then((resData) => {
        const fetchedReviews = [];
        for (const key in resData.data.comments) {
          fetchedReviews.push(resData.data.comments[key]);
        }
        setCount(resData.data.commentCount);
        setExperienceRating(resData.data.rating.experienceRating.rating);
        setHomeRating(resData.data.rating.homeRating.rating);
        setBuildingRating(resData.data.rating.buildingRating.rating);
        setLocationRating(resData.data.rating.locationRating.rating);
        setRegion(resData.data.region);
        setReviews(fetchedReviews);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(true);
      });
  }, [props.match.params.home]);

  let renderedReviews = <Spinner />;
  if (!loading) {
    const allReviews =
      reviews == null
        ? null
        : reviews.map((review) => (
            <Review
              key={review._id}
              rating={review.experienceRating}
              priceWhole={review.priceWhole}
              priceRoom={review.priceRoom}
              roomLive={review.roomLive}
              floorNo={review.floorNumber}
              bathroomNo={review.bathroomNumber}
              bedroomNo={review.bedroomNumber}
              yearInCollege={review.yearInCollege}
              yearLiveIn={review.yearLiveIn}
              comment={review.commentBody}
            />
          ));
    renderedReviews = (
      <React.Fragment>
        <Header
          count={count}
          experienceRating={experienceRating}
          homeRating={homeRating}
          buildingRating={buildingRating}
          locationRating={locationRating}
          campus={region}
        />
        <h1 className={classes.Layout}>Reviews</h1>
        {allReviews}
      </React.Fragment>
    );
  }

  return (
    <div className={classes.Container}>
      <Control address={props.match.params.home} />

      {renderedReviews}
    </div>
  );
};

export default withRouter(ReviewsPage);
