import React from 'react';
import { Link } from 'react-router-dom';

import Button from './Button/Button';

import classes from './Overview.module.scss';

const Overview = () => {
  return (
    <div className={classes.Overview}>
      <div className={classes.Container}>
        <h1 className={classes.H1}>
          Demystify housing options outside of UofT campuses
          <br></br>
        </h1>
        <h2 className={classes.H1}>
          Find your next&nbsp;
          <b style={{ fontWeight: '800', fontSize: '160%' }}>UofTHome</b>
          <br></br>
          <br></br>
        </h2>
        <Button>
          <Link to='/campuses'>See All UofTHomes</Link>
        </Button>
      </div>
    </div>
  );
};

export default Overview;
