import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretLeft } from '@fortawesome/free-solid-svg-icons';
// import * as actions from '../../store/actions/index';

import SwitchButton from '../UI/Buttons/SwitchButton/SwitchButton';
import Spinner from '../UI/Spinner/Spinner';
import CampusesVideo from '../UI/CampusesVideo/CampusesVideo';

import classes from './CampusesPage.module.scss';

const CampusesPage = (props) => {
  // Scroll to top when component rendered
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [width, setWidth] = useState(window.innerWidth);

  const resizeHandler = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    return function cleanup() {
      window.removeEventListener('resize', resizeHandler);
    };
  });

  let video = <CampusesVideo width={'100%'} height={'100%'} opacity={'0.8'} />;
  if (width < 400) {
    video = null;
  }

  const [campus, setCampus] = useState('UTSG');
  const { UTSG, UTSC, UTM, loading } = props;

  const switchCampusHandler = (campus) => {
    setCampus(campus);
  };

  // Implement pagination
  const perPage = 7;

  const [offset, setOffset] = useState(0);
  // eslint-disable-next-line
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    setOffset(0);
    setCurrentPage(1);
  }, [campus]);

  var homes = null;
  let showHomes = <Spinner />;
  let pageCount = 0;
  if (!loading) {
    switch (campus) {
      case 'UTSG':
        homes = UTSG.data.map((home) => {
          return (
            <Link key={home.name} to={'/reviews/' + home.name}>
              <h3>{home.name}</h3>
            </Link>
          );
        });
        break;
      case 'UTSC':
        homes = UTSC.data.map((home) => {
          return (
            <Link key={home.name} to={'/reviews/' + home.name}>
              <h3>{home.name}</h3>
            </Link>
          );
        });

        break;
      case 'UTM':
        homes = UTM.data.map((home) => {
          return (
            <Link key={home.name} to={'/reviews/' + home.name}>
              <h3>{home.name}</h3>
            </Link>
          );
        });
        break;
      default:
        break;
    }
    pageCount = homes.length / 7;
    showHomes = homes.slice(offset, offset + perPage);
  }

  const pageHandler = (e) => {
    const selectedPage = e.selected;
    const currentOffset = selectedPage * perPage;

    setOffset(currentOffset);
    setCurrentPage(selectedPage);

    showHomes = homes.slice(offset, offset + perPage);
  };

  return (
    <div className={classes.Campuses}>
      <div className={[classes.Split, classes.Left].join(' ')}>
        <h1 style={{ marginBottom: '30px' }}>All UofTHomes</h1>
        <SwitchButton campus={campus} setCampus={switchCampusHandler} />
        <div className={classes.homesContainer}>
          <div>{showHomes}</div>
          <ReactPaginate
            previousLabel={<FontAwesomeIcon icon={faCaretLeft} />}
            nextLabel={<FontAwesomeIcon icon={faCaretRight} />}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={1}
            pageRangeDisplayed={1}
            onPageChange={pageHandler}
            containerClassName={classes.pagination}
            subContainerClassName={[classes.pages, classes.pagination].join(
              ' '
            )}
            activeClassName={classes.active}
          />
        </div>
      </div>
      <div className={[classes.Split, classes.Right].join(' ')}>{video}</div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.homes.loading,
    UTSG: state.homes.UTSG,
    UTSC: state.homes.UTSC,
    UTM: state.homes.UTM,
  };
};

export default connect(mapStateToProps)(CampusesPage);
