import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import utsgImage from '../../assets/img/utsg.jpg';
import utscImage from '../../assets/img/utsc.jpg';
import utmImage from '../../assets/img/utm.jpg';

import Button from '../UI/Buttons/JumpButton/JumpButton';

import classes from './YayPage.module.scss';

const YayPage = (props) => {
  // Scroll to top when component rendered
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [width, setWidth] = useState(window.innerWidth);

  const resizeHandler = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    return function cleanup() {
      window.removeEventListener('resize', resizeHandler);
    };
  });

  // Conditionally render background image
  let imageSrc = utsgImage;
  switch (props.location.state.campus) {
    case 'UTSC':
      imageSrc = utscImage;
      break;
    case 'UTM':
      imageSrc = utmImage;
      break;
    default:
      break;
  }

  let image = null;
  if (width > 600) {
    image = (
      <img
        src={imageSrc}
        alt='Campus'
        style={{ width: '100%', opacity: '0.4', margin: 'auto' }}
      />
    );
  }

  return (
    <div className={classes.Yay}>
      <div className={classes.Container}>
        <h1>Thanks for rating!</h1>
        <Button style={{ marginTop: '-10px' }}>
          <Link to='/'>Back to Home</Link>
        </Button>
      </div>
      {image}
    </div>
  );
};

export default withRouter(YayPage);
