import React from 'react';

import Toolbar from '../../components/Navigation/Toolbar/Toolbar';
import Footer from '../../components/UI/Footer/Footer';

import classes from './Layout.module.scss';

const Layout = (props) => {
  return (
    <React.Fragment>
      <Toolbar />
      <main className={classes.Main}>{props.children}</main>
      <Footer />
    </React.Fragment>
  );
};

export default Layout;
