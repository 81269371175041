import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Address from './Address/Address';
import Form from './Form/Form';
import Spinner from '../UI/Spinner/Spinner';
import classes from './RatePage.module.scss';

const RatePage = (props) => {
  // Scroll to top when component rendered
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { loading } = props;

  const [address, setAddress] = useState('');
  const [newBuilding, setNewBuilding] = useState(false);

  if (
    props.location.state !== null &&
    props.location.state !== undefined &&
    address === ''
  ) {
    setAddress(props.location.state.address);
  }

  const addressHandler = (val) => {
    setAddress(val);
  };

  const newBuildingHandler = () => {
    setNewBuilding(true);
  };

  // Address component
  const addressIdentifier = (
    <React.Fragment>
      <Address
        set={address === ''}
        new={newBuilding}
        address={address}
        onChangeAddress={addressHandler}
        onChangeNew={newBuildingHandler}
      />
    </React.Fragment>
  );

  // Loading UI
  let body = <Spinner />;
  if ((!loading && address !== '') || newBuilding) {
    body = (
      <div>
        {addressIdentifier}
        <Form address={address} />
      </div>
    );
  } else if (!loading && address === '') {
    body = <div>{addressIdentifier}</div>;
  }

  // console.log(style);
  return (
    <div className={classes.Layout}>
      <div>
        <h1>Rate Your UofTHome!</h1>
      </div>
      {body}
      {newBuilding}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.homes.loading,
  };
};

export default connect(mapStateToProps, null)(withRouter(RatePage));
