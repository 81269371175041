// import React, { Suspense } from 'react';
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Layout from './hoc/Layout/Layout';
import LandingPage from './components/LandingPage/LandingPage';
// import Spinner from './components/UI/Spinner/Spinner';

import CampusesPage from './components/CampusesPage/CampusesPage';
import ReviewsPage from './components/ReviewsPage/ReviewsPage';
import RatePage from './components/RatePage/RatePage';
import YayPage from './components/YayPage/YayPage';
// import AboutUsPage from './components/AboutUsPage/AboutUsPage'
// import TeamPage from './components/TeamPage/TeamPage'

// const CampusesPage = React.lazy(() =>
//   import('./components/CampusesPage/CampusesPage')
// );
// const ReviewsPage = React.lazy(() =>
//   import('./components/ReviewsPage/ReviewsPage')
// );
// const RatePage = React.lazy(() => import('./components/RatePage/RatePage'));
// const YayPage = React.lazy(() => import('./components/YayPage/YayPage'));
// const AboutUsPage = React.lazy(() =>
//   import('./components/AboutUsPage/AboutUsPage')
// );
// const TeamPage = React.lazy(() => import('./components/TeamPage/TeamPage'));

const App = () => {
  return (
    <div>
      <Helmet>
        <title>UofTHome | Students' housing & rental experiences @UofT</title>
        <meta
          name='description'
          content='Review your housing & rental experiences living off-campus at University of Toronto. Learn about the most popular and affordable condos near school.'
        />
      </Helmet>
      <Switch>
        <Layout>
          {/* <Route
            path='/aboutus'
            render={() => (
              <Suspense fallback={<Spinner />}>
                <AboutUsPage />
              </Suspense>
            )}
          />
          <Route
            path='/team'
            render={() => (
              <Suspense fallback={<Spinner />}>
                <TeamPage />
              </Suspense>
            )}
          /> */}
          {/* <Route
            path='/campuses'
            render={() => (
              <Suspense fallback={<Spinner />}>
                <CampusesPage />
              </Suspense>
            )}
          /> */}
          {/* <Route
            path='/rate'
            render={() => (
              <Suspense fallback={<Spinner />}>
                <RatePage />
              </Suspense>
            )}
          /> */}
          {/* <Route
            path='/yay'
            render={() => (
              <Suspense fallback={<Spinner />}>
                <YayPage />
              </Suspense>
            )}
          /> */}
          {/* <Route
            path='/reviews/:home'
            render={() => (
              <Suspense fallback={<Spinner />}>
                <ReviewsPage />
              </Suspense>
            )}
          /> */}
          <Route path='/campuses' component={CampusesPage} />
          <Route path='/rate' component={RatePage} />
          <Route path='/yay' component={YayPage} />
          <Route path='/reviews/:home' component={ReviewsPage} />
          <Route path='/' exact component={LandingPage} />
          <Redirect to='/' />
        </Layout>
      </Switch>
    </div>
  );
};

export default App;
