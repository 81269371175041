import * as actionTypes from './actionTypes';
import axios from '../../utils/axios/axios-homes';

const uppercase = (res) => {
  const data = res.map((el) => {
    const splitEl = el.name.split(' ');
    const joinEl = splitEl
      .map((word) => {
        if (word.charAt(0).match(/^[0-9]+$/) === null) {
          return word[0].toUpperCase() + word.slice(1).toLowerCase();
        }
        return word;
      })
      .join(' ');
    return { name: joinEl, region: el.region };
  });
  return data;
};

export const fetchUTSG = () => {
  return (dispatch) => {
    return axios
      .get('https://uofthome.herokuapp.com/building/region/address?region=UTSG')
      .then((res) => {
        const data = uppercase(res.data);
        dispatch(setUTSG(data));
      })
      .catch(() => {
        dispatch(fetchFailed());
      });
  };
};

export const fetchUTSC = () => {
  return (dispatch) => {
    axios
      .get('https://uofthome.herokuapp.com/building/region/address?region=UTSC')
      .then((res) => {
        const data = uppercase(res.data);
        dispatch(setUTSC(data));
      })
      .catch(() => {
        dispatch(fetchFailed());
      });
  };
};

export const fetchUTM = () => {
  return (dispatch) => {
    axios
      .get('https://uofthome.herokuapp.com/building/region/address?region=UTM')
      .then((res) => {
        const data = uppercase(res.data);
        dispatch(setUTM(data));
      })
      .catch(() => {
        dispatch(fetchFailed());
      });
  };
};

export const fetchFailed = () => {
  return {
    type: actionTypes.FETCH_FAILED,
  };
};

export const setUTSG = (homes) => {
  return {
    type: actionTypes.SET_UTSG,
    homes: homes,
  };
};

export const setUTSC = (homes) => {
  return {
    type: actionTypes.SET_UTSC,
    homes: homes,
  };
};

export const setUTM = (homes) => {
  return {
    type: actionTypes.SET_UTM,
    homes: homes,
  };
};
