import React, { useEffect } from 'react';

import Overview from './Overview/Overview';
import Statistics from './Statistics/Statistics';
import Campuses from './Campuses/Campuses';

const LandingPage = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Overview />
      <Statistics />
      <Campuses />
    </div>
  );
};

export default LandingPage;
