import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import logoDark from '../../../assets/img/logo-dark.png';
import logoDarkSmall from '../../../assets/img/logo-dark-small.png';

import classes from './Logo.module.scss';

const Logo = (props) => {
  const [width, setWidth] = useState(window.innerWidth);

  const resizeHandler = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    return function cleanup() {
      window.removeEventListener('resize', resizeHandler);
    };
  });

  let logo = logoDarkSmall;
  if (width > 900) {
    logo = logoDark;
  } else {
    logo = logoDarkSmall;
  }

  return (
    <div className={classes.Logo} style={{ height: props.height }}>
      <Link to='/'>
        <img src={logo} alt='Logo' />
      </Link>
    </div>
  );
};

export default Logo;
