import * as actionTypes from '../actions/actionTypes';

const initialState = {
  loading: true,
  UTSG: { isFetched: false, data: [] },
  UTSC: { isFetched: false, data: [] },
  UTM: { isFetched: false, data: [] },
  error: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_UTSG:
      return {
        ...state,
        loading: false,
        UTSG: {
          isFetched: true,
          data: action.homes,
        },
      };
    case actionTypes.SET_UTSC:
      return {
        ...state,
        loading: false,
        UTSC: {
          isFetched: true,
          data: action.homes,
        },
      };
    case actionTypes.SET_UTM:
      return {
        ...state,
        loading: false,
        UTM: {
          isFetched: true,
          data: action.homes,
        },
      };
    case actionTypes.SET_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};

export default reducer;
