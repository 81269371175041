import React from 'react';

import classes from './Header.module.scss';
import utsgImage from '../../../assets/img/utsg.jpg';
import utscImage from '../../../assets/img/utsc.jpg';
import utmImage from '../../../assets/img/utm.jpg';
import Star from '../Star/Star';

const Header = (props) => {
  let image = utsgImage;
  switch (props.campus) {
    case 'utsc':
      image = utscImage;
      break;
    case 'utm':
      image = utmImage;
      break;
    default:
      break;
  }

  let count = <h5>Based on {props.count} review</h5>;
  if (props.count > 1) {
    count = <h5>Based on {props.count} reviews</h5>;
  }

  return (
    <div className={classes.Header}>
      <div className={classes.Ratings}>
        <h2>Average Rating</h2>
        <div>
          <h3>Experience</h3>
          <Star rating={props.experienceRating} />
          <h4>{(Math.round(props.experienceRating * 10) / 10).toString()}</h4>
        </div>
        <div>
          <h3>Home</h3>
          <Star rating={props.homeRating} />
          <h4>{(Math.round(props.homeRating * 10) / 10).toString()}</h4>
        </div>
        <div>
          <h3>Building</h3>
          <Star rating={props.buildingRating} />
          <h4>{(Math.round(props.buildingRating * 10) / 10).toString()}</h4>
        </div>
        <div>
          <h3>Location</h3>
          <Star rating={props.locationRating} />
          <h4>{(Math.round(props.locationRating * 10) / 10).toString()}</h4>
        </div>

        {count}
      </div>
      <div className={classes.Image}>
        <img src={image} alt='Campus' />
      </div>
    </div>
  );
};

export default Header;
