import React from 'react';

import classes from './SwitchButton.module.scss';

const SwitchButton = (props) => {
  const UTSGButton = [classes.Button];
  const UTSCButton = [classes.Button];
  const UTMButton = [classes.Button];

  switch (props.campus) {
    case 'UTSG':
      UTSGButton.push(classes.Active);
      break;
    case 'UTSC':
      UTSCButton.push(classes.Active);
      break;
    case 'UTM':
      UTMButton.push(classes.Active);
      break;
    default:
      break;
  }

  return (
    <div className={classes.Container}>
      <div className={classes.Buttons}>
        <button
          className={UTSGButton.join(' ')}
          onClick={() => {
            props.setCampus('UTSG');
          }}
        >
          UTSG
        </button>
        &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;
        <button
          className={UTSCButton.join(' ')}
          onClick={() => {
            props.setCampus('UTSC');
          }}
        >
          UTSC
        </button>
        &nbsp;/&nbsp;&nbsp;&nbsp;
        <button
          className={UTMButton.join(' ')}
          onClick={() => {
            props.setCampus('UTM');
          }}
        >
          UTM
        </button>
        <button
          style={{
            display: 'none',
            height: '50px',
            borderBottom: '3px solid transparent',
          }}
        >
          1
        </button>
      </div>
    </div>
  );
};

export default SwitchButton;
