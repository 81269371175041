import React from 'react';

import campusesVideoMp4 from '../../../assets/video/campuses.mp4';
import campusesVideoWebm from '../../../assets/video/campuses.webm';

import classes from './CampusesVideo.module.scss';

const CampusesVideo = (props) => {
  /* <Player fluid autoplay src={campusesVideoMp4} className={classes.Video} /> */
  return (
    <video
      autoPlay='autoplay'
      loop='loop'
      muted
      className={classes.Video}
      style={{
        opacity: props.opacity,
        width: props.width,
        height: props.height,
      }}
    >
      <source src={campusesVideoMp4} type='video/mp4' />
      <source src={campusesVideoWebm} type='video/webm' />
      Your browser does not support this
    </video>
  );
};

export default CampusesVideo;
