import React from 'react';

import Star from '../Star/Star';

import classes from './Review.module.scss';

const Review = (props) => {
  let rent = null;
  if (props.priceWhole === 0 && props.priceRoom === 0) {
    rent = <p style={{ fontWeight: '700' }}>Own the home</p>;
  } else if (props.priceRoom === 0) {
    rent = (
      <p style={{ fontWeight: '700', marginBlockEnd: '0.4em' }}>
        ${props.priceWhole} / month
      </p>
    );
  } else if (props.priceWhole === 0) {
    rent = (
      <p style={{ fontWeight: '700', marginBlockEnd: '0.4em' }}>
        ${props.priceRoom} / month for the room
      </p>
    );
  } else {
    rent = (
      <React.Fragment>
        <p style={{ fontWeight: '700', marginBlockEnd: '0.4em' }}>
          ${props.priceWhole} / month
        </p>
        <p style={{ fontSize: '70%', fontWeight: '700', color: '#7e7e7e' }}>
          ${props.priceRoom} for the room
        </p>
      </React.Fragment>
    );
  }

  let reviewer = null;
  if (props.yearInCollege !== 'N/A') {
    reviewer = (
      <p className={classes.userInfo}>
        Reviewed by a {props.yearInCollege} who lived here in {props.yearLiveIn}
      </p>
    );
  }

  let floor = null;
  if (props.floorNo !== 0) {
    floor = (
      <strong style={{ fontWeight: '700' }}>
        &nbsp;on Floor {props.floorNo}{' '}
      </strong>
    );
  }

  return (
    <div className={classes.Review}>
      <div>
        <Star rating={props.rating} />
        <p>{props.rating}</p>
      </div>
      {rent}
      <p style={{ marginBlockEnd: '0.3em' }}>
        <strong style={{ fontWeight: '700' }}>{props.roomLive}</strong>
        {floor}
      </p>
      <React.Fragment>
        <p
          style={{
            fontSize: '70%',
            fontWeight: '700',
            color: '#7e7e7e',
            marginBlockStart: '0em',
          }}
        >
          {props.bedroomNo} Bedrooms {props.bathroomNo} Bathrooms
        </p>
      </React.Fragment>
      <p style={{ fontWeight: '500' }}>{props.comment}</p>
      {reviewer}
    </div>
  );
};

export default Review;
