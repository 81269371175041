import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import classes from './Campuses.module.scss';
import Spinner from '../../UI/Spinner/Spinner';

const Campuses = (props) => {
  const [UTSG, setUTSG] = useState('');
  const [UTSC, setUTSC] = useState('');
  const [UTM, setUTM] = useState('');
  const [loading, setLoading] = useState(true);

  // Helper function to uppercase the first letter
  const uppercase = (el) => {
    const splitEl = el.address.split(' ');
    const joinEl = splitEl
      .map((word) => {
        if (word.charAt(0).match(/^[0-9]+$/) === null) {
          return word[0].toUpperCase() + word.slice(1).toLowerCase();
        }
        return word;
      })
      .join(' ');
    return joinEl;
  };

  async function fetchUTSGdata() {
    const UTSGdata = await axios.get(
      'https://uofthome.herokuapp.com/building/region/top-ten?region=utsg'
    );

    setUTSG(UTSGdata.data);
    setLoading(false);
  }

  async function fetchUTSCdata() {
    const UTSCdata = await axios.get(
      'https://uofthome.herokuapp.com/building/region/top-ten?region=utsc'
    );
    setUTSC(UTSCdata.data);
    setLoading(false);
  }

  async function fetchUTMdata() {
    const UTMdata = await axios.get(
      'https://uofthome.herokuapp.com/building/region/top-ten?region=utm'
    );
    setUTM(UTMdata.data);
    setLoading(false);
  }

  useEffect(() => {
    fetchUTSGdata();
    fetchUTSCdata();
    fetchUTMdata();
  }, []);

  let campuses = (
    <Spinner
      style={{ display: 'none', justifyContent: 'none', alignItems: 'none' }}
    />
  );
  // let campuses = null;
  if (!loading) {
    let UTSGhomes = '';
    let UTSChomes = '';
    let UTMhomes = '';

    if (UTSG !== '') {
      UTSGhomes = UTSG.map((oldHome) => {
        const home = uppercase(oldHome);

        return (
          <Link key={home} to={'/reviews/' + home}>
            {home}
          </Link>
        );
      }).slice(0, 5);
    }

    if (UTSC !== '') {
      UTSChomes = UTSC.map((oldHome) => {
        const home = uppercase(oldHome);
        return (
          <Link key={home} to={'/reviews/' + home}>
            {home}
          </Link>
        );
      }).slice(0, 5);
    }

    if (UTM !== '') {
      UTMhomes = UTM.map((oldHome) => {
        const home = uppercase(oldHome);
        return (
          <Link key={home} to={'/reviews/' + home}>
            {home}
          </Link>
        );
      }).slice(0, 5);
    }

    campuses = (
      <div className={classes.ContainerFlex}>
        <div className={classes.Campus}>
          <h2>UTSG</h2>
          {UTSGhomes}
        </div>
        <div className={classes.Campus}>
          <h2>UTSC</h2>
          {UTSChomes}
        </div>
        <div className={classes.Campus}>
          <h2>UTM</h2>
          {UTMhomes}
        </div>
      </div>
    );
  }

  return (
    <div className={classes.Campuses}>
      <div className={classes.Container}>
        <div className={classes.Flexbox}>
          <h2>Popular condos near University of Toronto</h2>
          <Link to='/campuses'>
            <button>
              <h2>Explore All</h2>
            </button>
          </Link>
        </div>
        {campuses}
      </div>
    </div>
  );
};

export default Campuses;
