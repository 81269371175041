import React from 'react';

import spacemo from '../../../assets/img/spacemo.png';

import classes from './SpaceMo.module.scss';

const SpaceMo = (props) => {
  return (
    <div className={classes.SpaceMo}>
      <img src={spacemo} alt='SpaceMo' height={props.height} />
    </div>
  );
};

export default SpaceMo;
