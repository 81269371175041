import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import SpaceMo from '../../UI/SpaceMo/SpaceMo';
import Button from './Button/Button';
import Spinner from '../../UI/Spinner/Spinner';

import classes from './Statistics.module.scss';

const Statistics = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const resizeHandler = () => {
    setWidth(window.innerWidth);
  };

  const resizeHeightHandler = () => {
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    window.addEventListener('resize', resizeHeightHandler);
    return function cleanup() {
      window.removeEventListener('resize', resizeHandler);
      window.removeEventListener('resize', resizeHeightHandler);
    };
  });

  let share = <h2>Share your rental experience with your friends!</h2>;
  if (height < 620 && width < 600) {
    share = null;
  } else if (width < 600) {
    share = (
      <h2>
        Share your rental experience<br></br> with your friends!
      </h2>
    );
  }

  const [isLoading, setIsLoading] = useState(true);
  const [commentCount, setCommentCount] = useState(18);
  const [buildingCount, setBuildingCount] = useState(6);

  let stats = <Spinner />;
  if (!isLoading) {
    stats = (
      <React.Fragment>
        <p>
          UofTHome currently has <br></br>
          <b>{commentCount} reviews</b> from <b>{buildingCount} buildings</b>!
        </p>
        <Button>
          <Link to={{ pathname: '/rate' }}>Write A Review!</Link>
        </Button>
      </React.Fragment>
    );
  }

  useEffect(() => {
    axios.get('https://uofthome.herokuapp.com/comment/count').then((res) => {
      setCommentCount(res.data);
    });
    axios.get('https://uofthome.herokuapp.com/building/count').then((res) => {
      setBuildingCount(res.data);
      setIsLoading(false);
    });
  }, [buildingCount, commentCount]);

  return (
    <div className={classes.Statistics}>
      <div className={classes.Container}>
        {share}
        <div className={classes.Stats}>
          <div className={classes.Ver}>{stats}</div>
          <div className={classes.Ver}>
            <SpaceMo height={'100px'} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Statistics;
